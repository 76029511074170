<template>
  <div
    class="card card--interactive item"
    :class="cardClasses"
  >
    <input
      :id="fieldId"
      ref="input"
      class="item__input"
      :name="group"
      :value="value"
      :type="inputType"
      :checked="checked"
      :disabled="disabled"
      @change="changed"
    >
    <label
      :class="labelClasses"
      class="item__label"
      :for="fieldId"
    >
      <slot />
    </label>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'ItemSelector',
  props: {
    fieldId: { type: String, required: true },
    // Used to group several input fields. Equivalent to `name`
    group: { type: String, required: true },
    value: { type: [String, Number, Boolean], default: '' },

    // Controls weather we render a type=checkbox or type=radio, defaults to radio
    multiple: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    visibleCheckmark: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedClass: '',
    }
  },
  computed: {
    inputType() {
      return this.multiple ? 'checkbox' : 'radio'
    },
    cardClasses() {
      const classNames = [`item-selector__${this.inputType}`]
      if (this.disabled) classNames.push('card--disabled')
      if (this.checked) classNames.push('card--emphasized')
      return classNames
    },
    labelClasses() {
      const classNames = []
      if (this.visibleCheckmark) classNames.push('item__label--visible-checkmark')
      if (this.$slots.footer) classNames.push('item__label--compact')
      return classNames
    },
  },

  methods: {
    changed() {
      this.$emit('on-change', !!this.$refs.input.checked, this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.item__label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: -13px;
  padding: 13px;
}
.item__label--visible-checkmark {
  padding-left: 4.5rem;
  &::before {
    border: 1px solid;
    border-radius: 20px;
    content: '';
    height: 1.875rem;
    position: absolute;
    width: 1.875rem;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    border: 3px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 12px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    width: 6px;
    top: 50%;
    left: 28px;
    transform: translateY(-50%) rotate(45deg);
  }
}
.item__label--compact {
  padding-bottom: 0;
  margin-bottom: 0;
}
.item__input {
  height: 1.875rem;
  opacity: 0;
  position: absolute;
  width: 1.875rem;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  &:checked + .item__label::before {
    background: #000;
  }
  &:checked + .item__label::after {
    opacity: 1;
  }
  &:focus + .item__label::before {
    box-shadow: 0 0 0 5px $focus-color;
  }
}
/* NOTE: used through an interpolated string on `cardClasses()` property: */
.item-selector__checkbox {
  margin-bottom: 16px;
  &:focus,
  &:hover,
  &:active {
    box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.1);
  }
}
.item-selector__radio {
  margin-top: 1.125rem;
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.1);
  }
}

.card--disabled {
  background: #e0e0e0;
  border: 1px solid #c4c4c4;
  color: #7e7e7e !important;
  cursor: not-allowed;
  &:focus,
  &:active,
  &:hover {
    box-shadow: none !important;
  }
}

/* END NOTE */

.item-selector__radio--disable {
  border-color: $sc-dark-grey;

  .item__label--visible-checkmark:before {
    border-color: $sc-dark-grey;
    background-color: $sc-light-grey;
  }

  .item__label {
    border-color: $sc-dark-grey;
  }

  .drop-off-point {
    color: $sc-dark-grey;
  }

  .item-selector__info-icon {
    margin-right: 0.3125rem;
  }
}
</style>
<style lang="scss">
@import '@/assets/styles/_variables.scss';

.item-selector__radio--disable .badge--dark {
  background-color: $sc-dark-grey;
}
</style>
